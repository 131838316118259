<template>
  <div id="lom-award-create">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
        <v-spacer></v-spacer>
        <v-btn
          @click.prevent="refreshPageData"
          color="#a4c639"
          min-width="92"
          rounded
          medium
          class="font-size-h6 px-3 py-3 mb-4 white--text"
          >Refresh
        </v-btn>
      </v-card-title>

      <!-- <v-card-subtitle>
          <h5>{{ PageDescription }}</h5>
        </v-card-subtitle> -->
      <v-card-text>
        <div class="d-flex justify-content-center mt-2 mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="searchForm"
        >
          <v-row>
            <v-col cols="12" sm="6" lg="4" md="6">
              <h6><span class="text-danger">* </span> Zone</h6>
              <v-autocomplete
                :reduce="(option) => option.value"
                :loading="ZoneCodeOptionsLoading"
                :items="ZoneCodeOptions"
                :rules="ZoneCodeRules"
                v-model="ZoneCode"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" lg="4" md="6">
              <h6>Lom <small>(Optional)</small></h6>
              <v-autocomplete
                :reduce="(option) => option.value"
                :loading="LomCodeOptionsLoading"
                :items="LomCodeOptions"
                :rules="LomCodeRules"
                v-model="LomCode"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" lg="4" md="6">
              <h6>NP-Recognition Category <small>(Optional)</small></h6>
              <v-autocomplete
                :reduce="(option) => option.value"
                :loading="RecognitionCategoryOptionsLoading"
                :items="RecognitionCategoryOptions"
                :rules="RecognitionCategoryRules"
                v-model="RecognitionCategory"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" md="12" align="center">
              <v-btn
                :disabled="!valid1"
                @click.prevent="searchForm"
                :loading="SubmitFlag"
                rounded
                medium
                color="#1db954"
                class="font-size-h6 px-4 mr-3 white--text"
              >
                Search
              </v-btn>
              <v-btn
                @click.prevent="resetAll"
                rounded
                medium
                color="brown"
                class="font-size-h6 px-4 mr-3 white--text"
              >
                Reset
              </v-btn>
            </v-col>
            <!-- <v-col cols="12" md="12" align="center">
              <v-btn
                v-if="DownloadFlag"
                color="#8a2be2"
                @click.prevent="
                  generateExcel(
                    tableData1,
                    ExcelFields,
                    ExcelFileName,
                    'My Worksheet'
                  )
                "
                class="btn btn-primary font-size-h6 py-4 my-3 mr-3 white--text"
                >Download</v-btn
              >
              <br />
            </v-col> -->
          </v-row>

          <v-container class="py-0" v-if="tableData1.length > 0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3 class="red--text">
                  {{ tableData1.length }} records found.
                </h3>
                <json-excel
                  v-if="tableData1.length > 0 && DownloadFlag"
                  :name="ExcelFileName"
                  :data="tableData1"
                  :fields="ExcelFields"
                >
                  <v-btn color="info" class="mr-4"> Download </v-btn>
                </json-excel>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <br />
                <v-data-table
                  class="elevation-1"
                  v-model="selected1"
                  :search="search"
                  :item-key="tableOptions1.ItemKey"
                  :single-select="tableOptions1.SingleSelectFlag"
                  :show-select="tableOptions1.ShowSelectFlag"
                  :headers="tableColumns1"
                  :items="tableData1"
                  :items-per-page="tableOptions1.ItemsPerPage"
                  :footer-props="tableOptions1.FooterProps"
                >
                  <template v-slot:item.RecognitionStatusTxt="{ item }">
                    <v-chip :color="item.RecognitionStatusColor" draggable dark>
                      {{ item.RecognitionStatusTxt }}
                    </v-chip>
                  </template>
                  <template v-slot:item.Actions="{ item }">
                    <v-tooltip bottom v-if="item.PreviewButtonFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="primary"
                          @click="previewAlert(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-file-search
                        </v-icon>
                      </template>
                      <span> Preview </span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog v-model="previewRecordPrompt" persistent max-width="80%">
      <np-recognition-preview
        :previewRecordPrompt="previewRecordPrompt"
        :recordData="selectedData"
        @hidePreviewRecordPrompt="hidePreviewRecordPrompt($event)"
        v-if="previewRecordPrompt"
      ></np-recognition-preview>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import NpRecognitionPreview from "@/view/pages/erp/jci-award/np-recognition/NpRecognitionPreview.vue";

export default {
  mixins: [common],
  components: {
    NpRecognitionPreview,
  },
  data() {
    return {
      LoadingFlag: false,
      SubmitFlag: false,
      OverlayLoadingFlag: false,

      ProgessStart: 0,
      uploadPercentage: 0,

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      ZipDownloadFlag: false,
      ZipFileName: "",

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: 0,
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCodeRules: [],
      LomCode: 0,
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      RecognitionCategoryRules: [],
      RecognitionCategory: 0,
      RecognitionCategoryOptions: [],
      RecognitionCategoryOptionsLoading: false,

      rows: [],
      tableData1: [],
      tableOptions1: [],
      tableColumns1: [],

      recordData: {},
      selectedData: {},

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      ActiveStatus: "",

      valid: true,

      RoleId: 0,
      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
    RecognitionCategoryOptions: function () {
      console.log("watch RecognitionCategoryOptions");
      this.RecognitionCategoryOptionsLoading = false;
    },
    ZoneCode: function () {
      console.log("watch ZoneCode");

      this.LomAward = [];
      this.getLomCodeOptions();
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "np_recognition",
        Action: "report_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.LoadingFlag = true;
      this.getZoneCodeOptions();
      this.getLomCodeOptions();
      this.getRecognitionCategoryOptions();
      this.LoadingFlag = false;
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      this.ZoneCodeOptionsLoading = true;
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/year-wise-lom/zone-options";
      var selectbox1_conditions_array = {
        UserInterface: 4,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var ZoneCode = this.ZoneCode;
      console.log({ ZoneCode });
      if (ZoneCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        message += "Zone should not be empty. ";
      }
    },
    getRecognitionCategoryOptions() {
      console.log("getRecognitionCategoryOptions called");
      this.RecognitionCategoryOptionsLoading = true;
      var selectbox1_source = "RecognitionCategory";
      var selectbox1_destination = "RecognitionCategoryOptions";
      var selectbox1_url = "api/recognition-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    resetAll() {
      console.log("resetAll called");
      this.$refs.form1.reset();
      this.tableData1 = [];
      this.tableOptions1 = [];
      this.tableColumns1 = [];

      this.DownloadFlag = false;
      this.ExcelFileName = "";
      this.ExcelFields = {};

      this.ZipDownloadFlag = false;
      this.ZipFileName = "";
    },
    previewAlert(tr) {
      console.log("previewAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.previewRecordPrompt = true;
      } else {
        var message = "Kindly select one record to preview";
        this.errorMessage(message);
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    searchForm() {
      console.log("searchForm called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.DownloadFlag = false;
        this.ExcelFileName = "";
        this.ZipDownloadFlag = false;
        this.ZipFileName = "";
        this.tableData1 = [];
        this.selected1 = [];

        this.SubmitFlag = true;
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/recognition/lists";

        var zone = this.ZoneCode > 0 ? this.ZoneCode : null;
        var lom = this.LomCode > 0 ? this.LomCode : null;
        var recognition_category =
          this.RecognitionCategory > 0 ? this.RecognitionCategory : null;
        console.log({ zone }, { lom }, { recognition_category });
        var upload = {
          UserInterface: 1,
          Zone: zone,
          Lom: lom,
          RecognitionCategory: recognition_category,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = [];
        var table_content = [];
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.ProgessStart = 0;
            thisIns.SubmitFlag = false;
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            var options = records.TableOptions;
            thisIns.tableOptions1 = options;

            if (flag == 1) {
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableData1 = records.TableData;

              thisIns.DownloadFlag = options.DownloadFlag;
              thisIns.ExcelFileName = options.ExcelFileName;
              thisIns.ExcelFields = options.ExcelFields;
              thisIns.ZipDownloadFlag = options.ZipDownloadFlag;
              thisIns.ZipFileName = options.ZipFileName;

              thisIns.toast("success", output);
            } else {
              console.log("error=" + output);
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Kindy fill required fields and then search";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    console.log("beforeMount called");

    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? "" : RoleId;
    console.log({ RoleId });
    this.RoleId = RoleId;

    this.refreshPageData();
  },
};
</script>

<style lang="scss">
#lom-award-create {
  // .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  // .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  // .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  // .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  // .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
  // .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  //   padding: 0 2px;
  // }
  // .criteria-title {
  //   font-size: 20px;
  // }
  // .criteria-question-text {
  //   font-size: 18px;
  // }

  .notes-text {
    font-size: 18px;
    color: red;
  }
}
</style>
